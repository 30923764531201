import React from 'react';
import BlogPosts from './BlogPosts';
import Search from './Search';
import Youtube from './youtube';

function App() {
  return (
    <div className="App">
      <Youtube />
      {/* <Search /> */}
      {/* <BlogPosts /> */}
    </div>
  );
}

export default App;